import { useEffect, useRef } from 'react';
//Executes callback after component render except first one
//Use case: handling api requests which should execute only after client data update
const useClientEffect = (callback, dependencies) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) isFirstRun.current = false;
    else callback();
  }, dependencies);
};

export default useClientEffect;
